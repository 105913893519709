@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Schoolbell', cursive;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  transition: opacity 0.5s ease-out;
}

.fade-out {
  opacity: 0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #c13f5f;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #a32a4d;
}

.bottom{
    position: fixed;
    bottom: 0;
    
}
